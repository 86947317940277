<template>
  <default-layout>
    <v-container id="agent-office-add" fluid tag="section">
      <agent-office-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const AgentOfficeForm = () =>
  import('@/components/master-data/agent-office/form/agent-office-form');

export default {
  name: 'agent-office-add',
  components: {
    DefaultLayout,
    AgentOfficeForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('agentOffice/form/restoreInitialState');
    this.$store.commit('agentOffice/form/SET_FORM_TYPE', 'ADD');
    await this.$store.dispatch('agentOffice/form/fetchBaseData');
  },
};
</script>
